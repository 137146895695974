import { theme } from "@/GlobalStyles";
import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const ContactUsPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
