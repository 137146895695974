import AlternativeHeroBanner from "@/common/alternative-hero-banner";
import { ContactUsPageContainer } from "@/contact-us/contact-us";
import { Helmet } from "react-helmet";

const ThankyouPage = () => {
  return (
    <ContactUsPageContainer>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11144377061"
        ></script>
        <script>{`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments)} gtag('js', new Date()); gtag('config', 'AW-11144377061'); `}</script>
        <script>{`gtag('event', 'conversion', {'send_to': 'AW-11144377061/jGuFCO3twZEZEOXlhsIp'});`}</script>
      </Helmet>
      <AlternativeHeroBanner
        PageName="Thank You"
        NavigateFrom="Home"
        NavigateTo="Thank You"
        IntroHeader="Thank you for your inquiry."
        introPara="Someone from the Pearl Team will contact you shortly."
        BgHero="contact-us-hero.jpg"
      />
    </ContactUsPageContainer>
  );
};

export default ThankyouPage;
